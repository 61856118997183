import React from "react";
import Layout from "../layouts";
import {graphql, Link} from "gatsby";
import {FormattedMessage} from "react-intl";
import {StaticImage} from "gatsby-plugin-image";
import Seo from "../components/Seo";

const ImprintPage = ({data, pageContext, location}) => {

    const {lang, slug} = pageContext;
    const {frontmatter, content} = data.homeContent.childMarkdownRemark;
    const {title} = frontmatter;

    return (
        <Layout lang={lang} showNav={false}>
            <Seo lang={lang} title={title} noIndex={true} location={location} slug={slug}/>
            <div className="flex flex-row md:items-center">
                <div className="flex-1">
                    <Link className="cursor-pointer text-sm" to={`/${lang}`}>{`❮ `}<FormattedMessage id="back"/> </Link>
                    <h1 className="text-2xl md:text-3xl font-bold mt-2 mb-8">{title}</h1>
                    <div className="text-lg " dangerouslySetInnerHTML={{__html: content}}/>
                </div>
                <div className="hidden md:inline-block flex-1 ml-16">
                    <StaticImage
                        src="../content/images/placeholder.png" alt=""
                        quality={90}/>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
query ImprintContent($lang: String){
    homeContent: file(
    sourceInstanceName: {eq: "pages"}
    relativeDirectory: {eq: "imprint"}
    name: {regex: "/index.*/"}
    childrenMarkdownRemark: {elemMatch: {frontmatter: {lang: {eq: $lang}}}}
  ) {
    childMarkdownRemark {
      frontmatter {
        title
      }
      content: html
    }
  }
}

`

export default ImprintPage
